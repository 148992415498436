import React from "react";
import { graphql, Link } from "gatsby"

import HeaderThanks from "../components/headerThanks";

//import Layout from "../components/layout";
import SEO from "../components/seo";
import logo from "../images/anticimex-logo.png";

function ThankYouPage(props) {
    const data = props.data.thanks

  return (
    <>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Thank you"
      />
      <HeaderThanks />
 


<section className="py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-24">
  <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
    <svg className="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404" role="img" aria-labelledby="svg-ax">
      <defs>
        <pattern id="ad119f34-7694-4c31-947f-5c9d249b21f3" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
          <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
        </pattern>
      </defs>
      <rect width="404" height="404" fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)" />
    </svg>

    <div className="relative">
    <h2 className="mt-1 text-4xl tracking-tight leading-10 font-extrabold ax-text-blue sm:leading-none sm:text-6xl lg:text-5xl xl:text-6xl text-center">
                  {data.heading}
          </h2>
      <blockquote className="mt-8">
        <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
          <div dangerouslySetInnerHTML={{ __html: data.body }} >
          </div>
        </div>
        <footer className="mt-8">
          <div className="md:flex md:items-center md:justify-center">
            <div className="md:flex-shrink-0">

              <div className="text-base text-center leading-6 font-medium text-gray-500">The M&A team</div>

            </div>
            <div className="mt-3 text-center md:mt-0 md:flex md:items-center">

              <svg className="hidden md:block mx-1 h-5 w-5 ax-text-blue" fill="currentColor" viewBox="0 0 20 20">
                <path d="M11 0h3L9 20H6l5-20z" />
              </svg>
              <img className="mx-auto w-24 h-auto" src={logo} />

            </div>
          </div>
        </footer>

        <span className="inline-flex rounded-md mt-8 w-full">
          <Link to="/" className="font-bold inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-full text-white ax-bg-green hover:bg-green-500 focus:outline-none focus:border-green-600 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out mx-auto">
          &larr; {data.buttonText}
          </Link>
        </span>
      </blockquote>

    </div>
  </div>
</section>



    </>
  );
}

export default ThankYouPage;

export const thanksQuery = graphql`
query($locale: String!) {
    thanks: datoCmsThankYou(locale: { eq: $locale }) {
        buttonText
        body
        heading
    }
  }
`
