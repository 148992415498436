//import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";
// import scrollTo from 'gatsby-plugin-smoothscroll';

import Logo from "../images/anticimex-logo.png";



function Header() {
  const [showText, setShowText] = useState(false);

  return (
    <header className="relative z-50 pt-6 pb-12 md:pt-14 mt:pb-14">
      <nav className="relative max-w-6xl mx-auto flex items-center justify-between px-4 sm:px-6">
            <div className="flex items-center flex-1">
              <div className="flex items-center justify-between w-full md:w-auto">
                <a href="/">
                  <img className="h-8 w-auto sm:h-10" src={Logo} alt="" />
                </a>
                <div className="-mr-2 flex items-center md:hidden">
                  <button onClick={() => setShowText(!showText)} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                    <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                  </button>
                </div>
              </div>
              {/* <div className="hidden md:block md:ml-10">
                <span onClick={() => scrollTo('#section-start')} className="cursor-pointer font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out">Start</span>
                <span onClick={() => scrollTo('#section-be-a-part')} className="cursor-pointer ml-10 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out">Be a part</span>
                <span onClick={() => scrollTo('#section-voices')} className="cursor-pointer ml-10 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out">Voices</span>
                <span onClick={() => scrollTo('#section-process')} className="cursor-pointer ml-10 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out">Process</span>
                <span onClick={() => scrollTo('#section-contact')} className="cursor-pointer ml-10 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out">Contact</span>
              </div> */}
            </div>
            <div className="hidden md:block text-right">
              <span className="inline-flex rounded-full shadow-md">
                <span className="inline-flex rounded-full shadow-xs">
                  <a href="https://www.anticimex.com" target="__new" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-6 font-medium rounded-full ax-text-blue bg-white hover:bg-gray-50 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                    Anticimex.com
              <svg className="-mr-1 ml-3 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5zM5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" clipRule="evenodd" />
                    </svg>
                  </a>
                </span>
              </span>
            </div>
          </nav>

          {showText &&
            <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
              <div className="rounded-lg shadow-md">
                <div className="rounded-lg bg-white shadow-xs overflow-hidden">
                  <div className="px-5 pt-4 flex items-center justify-between">
                    <div>
                      <img className="h-8 w-auto" src={Logo} alt="" />
                    </div>
                    <div className="-mr-2">
                      {/* CLOSE */}
                      <button onClick={() => setShowText(!showText)} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                        <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  {/* <div className="px-2 pt-2 pb-3">
                    <span onClick={() => scrollTo('#section-start')} className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">Start</span>
                    <span onClick={() => scrollTo('#section-be-a-part')}  href="#" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">Be a part</span>
                    <span onClick={() => scrollTo('#section-voices')} className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">Process</span>
                    <span onClick={() => scrollTo('#section-contact')}className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">Contact</span>
                  </div> */}
                  <div>
                    <a href="#" className="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100 hover:text-indigo-700 focus:outline-none focus:bg-gray-100 focus:text-indigo-700 transition duration-150 ease-in-out">
                      Go to Anticimex.com
            </a>
                  </div>
                </div>
              </div>
            </div>
          }
    </header>
  );
}

export default Header;
